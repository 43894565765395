;(function($) {

	$(document).ready(function() {

		// ---------- Ajax Posts ----------

		var transitionEndEvent = 'webkitTransitionEnd oTransitionEnd MSTransitionEnd transitionend';
		var lazyLoadRefreshEvent = 'lazyload.refresh';
		var ajax_posts_form = $('form#ajax-posts-form');

		if(ajax_posts_form.length && typeof ajax_posts_params !== 'undefined') {

			var ajax_posts_button = ajax_posts_form.find('.ajax-posts-btn');
			var loading_bar = ajax_posts_form.find('.loading-bar');
			var ajax_filters_button = ajax_posts_form.find('.filters-btn');
			var ajax_posts_search = ajax_posts_form.find('input[name=search]');
			var ajax_posts_filters = ajax_posts_form.find('.ajax-posts-filter');
			var ajax_posts_reset = ajax_posts_form.find('.ajax-posts-reset');
			var ajax_posts_content = ajax_posts_form.find('#ajax-posts');
			var ajax_no_results = ajax_posts_form.find('.no-results');

			ajax_posts_button.on('click', function() {
				ajax_posts_form.data('append', true);
			});

			ajax_posts_reset.on('click', function() {

				ajax_posts_filters.each(function() {

					var self = $(this);

					if(self.is('input[type=radio]') && self.attr('value') === '0') {
						self.trigger('click');
					}
					else if(self.is('select')) {
						self.prop('selectedIndex', 0);
						self.selectpicker('render');
						ajax_posts_form.submit();

					}
					else if(self.is('input[type=text]')) {
						self.val('');
						ajax_posts_form.submit();
					}

				});

				window.scrollTo(0, 0);
			});

			ajax_filters_button.on('click', function() {
				ajax_posts_form.data('append', false);
			});

			ajax_posts_filters.not('input[type=text]').debounce('change', function() {
				ajax_posts_form.data('append', false);
				ajax_posts_form.find('input[name=page]').val(1);
				ajax_posts_form.submit();
				$(this).closest('span').addClass('loading');
			}, 200);

			ajax_posts_form.on('submit', function(e) {
				e.preventDefault();
				e.stopImmediatePropagation();
				e.stopPropagation();

				ajax_posts_button.attr('disabled', true).addClass('loading');
				ajax_filters_button.attr('disabled', true).addClass('loading');
				loading_bar.addClass('loading');

				var form_data = new FormData(ajax_posts_form[0]);
				form_data.append('action', ajax_posts_params.action);
				form_data.append('nonce', ajax_posts_params.nonce);

				$.ajax({
					url:         ajax_posts_params.admin_url,
					type:        'POST',
					dataType:    'json',
					data:        form_data,
					processData: false,
					contentType: false,
					success:     function(response, status, jqXHR) {

						ajax_posts_button.attr('disabled', false).addClass('loaded');
						ajax_filters_button.attr('disabled', false).addClass('loaded');
						ajax_posts_filters.closest('span').addClass('loaded');
						loading_bar.addClass('loaded');

						setTimeout(function() {
							ajax_posts_button.removeClass('loading loaded');
							ajax_filters_button.removeClass('loading loaded');
							ajax_posts_filters.closest('span').removeClass('loading loaded');
							loading_bar.removeClass('loading loaded');
						}, 500);

						if(response.success) {

							if(response.data.content) {
								ajax_no_results.addClass('d-none');
								ajax_posts_content.removeClass('d-none');

								if(ajax_posts_form.data('append') !== true) {
									ajax_posts_content.empty();
								}

								ajax_posts_content.append($(response.data.content));
								$(window).trigger(lazyLoadRefreshEvent);
							}
							else {
								ajax_posts_content.addClass('d-none');
								ajax_no_results.removeClass('d-none');
							}

							if(response.data.next_page === 0) {
								ajax_posts_button.addClass('d-none');
								ajax_posts_button.attr('disabled', true);
							}
							else {
								ajax_posts_form.find('input[name=page]').val(response.data.next_page);
								ajax_posts_button.removeClass('d-none');
							}

							if(ajax_posts_form.data('append') !== true) {

								$('html, body').stop().animate({
									scrollTop: 0
								}, 500);
							}

						}
						else {
							console.error(response.data.error);
						}
					}
				});

				return false;
			});
		}
	});

}(jQuery));

;(function($) {

	"use strict";

	var animationEndEvent = 'webkitAnimationEnd oAnimationEnd MSAnimationEnd animationend';
	var transitionEndEvent = 'webkitTransitionEnd oTransitionEnd MSTransitionEnd transitionend';
	var lazyLoadRefreshEvent = 'lazyload.refresh';
	var mobileBreakpoint = 1024;
	var scrollController = new ScrollMagic.Controller();

	function handleResize(mobile, desktop) {

		$(window).debounce('resize', function() {

			if(typeof mobile === 'function' && window.innerWidth < mobileBreakpoint && scrollController.enabled()) {
				mobile();
			}
			else if(typeof desktop === 'function' && window.innerWidth >= mobileBreakpoint && !scrollController.enabled()) {
				desktop();
			}

		}, 200);
	}

	$(document).ready(function() {

		//======================================
		//	!---------- ANIMATIONS ----------
		//======================================

		// ---------- Global ----------

		$('.animated-section').each(function() {

			var self = $(this);

			var sectionScene = new ScrollMagic.Scene({ triggerElement: this, triggerHook: 0.6, reverse: false })
				.setClassToggle(this, 'active')
				.addTo(scrollController);

			handleResize(function() {
				sectionScene.progress(1);
			});

		});

		// ---------- Header ----------

		$('.header-wrapper').each(function() {

			var self = $(this);

			var headerScene = new ScrollMagic.Scene()
				.on("update", function() {
					var direction = scrollController.info("scrollDirection");

					if($(window).scrollTop() > 50) {
						self.toggleClass('shrink', (direction === "FORWARD"));
						$('.loading-bar').toggleClass('shrink', (direction === "FORWARD"));
					}
				})
				.addTo(scrollController);

			handleResize(function() {
				headerScene.progress(0);
			});
		});

		// ---------- Dashboard ----------

		$('.sticky-content').each(function() {

			var self = $(this);
			var offset = ($(document.body).hasClass('dashboard-page')) ? 80 : 120;
			var sticky = self.stickybits({ useStickyClasses: true, useFixed: true, stickyBitStickyOffset: offset });

			$(window).throttle('resize', function() {
				self.width(self.parent().width());
				sticky.update();
			}, 200);
		});

		// ---------- Controller ----------

		handleResize(function() {
			scrollController.enabled(false);
		}, function() {
			scrollController.enabled(true).update(true);
		});

		$(window).trigger('resize');
	});

}(jQuery));

;(function($) {

	$(document).ready(function() {

		// ---------- Downloads ----------

		var transitionEndEvent = 'webkitTransitionEnd oTransitionEnd MSTransitionEnd transitionend';
		var downloads_form = $('form#downloads-form');

		if(downloads_form.length && typeof downloads_params !== 'undefined') {

			var downloads_button = downloads_form.find('button[type=submit]');
			var select_button = downloads_form.find('button.select-all');
			var checkboxes = downloads_form.find('input[type=checkbox]');

			select_button.text(select_button.data('select-label'));

			select_button.on('click', function() {
				var deselect_class = 'deselect-all';

				if(select_button.hasClass(deselect_class)) {
					select_button.removeClass(deselect_class);
					select_button.text(select_button.data('select-label'));
					checkboxes.attr('checked', false);
				}
				else {
					select_button.addClass(deselect_class);
					select_button.text(select_button.data('deselect-label'));
					checkboxes.attr('checked', true);
				}

				checkboxes.trigger('change');
			});

			checkboxes.on('change', function() {
				var checked = downloads_form.find(':checked').length;

				downloads_button.find('span').text(checked);
				downloads_button.attr('disabled', !(checked > 0));
			});

			downloads_button.on('click', function() {
				$(this).addClass('loading');
			});

			downloads_form.on('submit', function(e) {
				e.preventDefault();
				e.stopImmediatePropagation();
				e.stopPropagation();

				downloads_button.attr('disabled', true);

				var data = downloads_form.serializeArray().reduce(function(obj, item) {
					if(obj[item.name]) {
						if(obj[item.name] instanceof Array) {
							obj[item.name].push(item.value);
						}
						else {
							obj[item.name] = [obj[item.name], item.value];
						}
					}
					else {
						obj[item.name] = item.value;
					}
					return obj;
				}, {});

				data['action'] = downloads_params.action;
				data['nonce'] = downloads_params.nonce;

				$.ajax({
					url:      downloads_params.admin_url,
					type:     'POST',
					dataType: 'json',
					data:     data,
					success:  function(response, status, jqXHR) {

						downloads_button.attr('disabled', false);

						downloads_button.addClass('loaded').on(transitionEndEvent, function() {
							var self = $(this);

							self.off(transitionEndEvent);
							self.removeClass('loading loaded');
						});

						if(response.success) {
							window.location = response.data.file_url;
						}
						else {
							console.error(response.data.error);
						}
					}
				});

				return false;
			});
		}
	});

}(jQuery));

var clientId = "8tHZuR6QWpRK";
var embed;

(function($) {

	$(window).on('load', function() {
		var tag = document.createElement("script");
		tag.src = "https://www.gruveo.com/embed-api/";
		tag.id = "gruveo-script";
		var firstScriptTag = document.getElementsByTagName("script")[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

		$('#gruveo-modal').on('show.bs.modal', function() {
			var call_code = $('#call-btn').data('code');
			call_code = call_code.replace(/-/g, '');
			console.log(call_code);
			embed.call(call_code, true);
		});

		$('#gruveo-modal').on('hidden.bs.modal', function() {
			embed.end();
		});
	});

}(jQuery));


function onGruveoEmbedAPIReady() {
	embed = new Gruveo.Embed("gruveo", {
		width:       680,
		height:      465,
		embedParams: {
			clientid: clientId,
			color:    "63b2de",
			branding: false
		}
	});

	embed
		.on("requestToSignApiAuthToken", onEmbedRequestToSignApiAuthToken)
		.on("stateChange", onEmbedStateChange);
}

function onEmbedRequestToSignApiAuthToken(e) {

	jQuery.ajax({
		type:     'POST',
		dataType: 'json',
		url:      gruveo_token_params.admin_url,
		data:     {
			action:   gruveo_token_params.action,
			security: gruveo_token_params.nonce,
			token:    e.token
		},

		success: function(response) {
			if(response.data.tokenHmac) {
				embed.authorize(response.data.tokenHmac);
			}
		}
	});

}

function onEmbedStateChange(e) {
	if(e.state === "ready") {
		embed.end();
		jQuery('#gruveo-modal').modal('hide');
	}
}

;(function($) {

	$(document).ready(function() {

		// ---------- Newsletter ----------

		var transitionEndEvent = 'webkitTransitionEnd oTransitionEnd MSTransitionEnd transitionend';
		var newsletter_form = $('form#newsletter-form');

		if(newsletter_form.length && typeof newsletter_params !== 'undefined') {

			var newsletter_button = newsletter_form.find('button[type=submit]');

			newsletter_button.on('click', function() {
				newsletter_form.find('input[type=email]').removeClass('is-invalid');
				$(this).addClass('loading');
			});

			newsletter_form.on('submit', function(e) {
				e.preventDefault();
				e.stopImmediatePropagation();
				e.stopPropagation();

				newsletter_button.attr('disabled', true);

				var data = newsletter_form.serializeArray().reduce(function(obj, item) {
					if(obj[item.name]) {
						if(obj[item.name] instanceof Array) {
							obj[item.name].push(item.value);
						}
						else {
							obj[item.name] = [obj[item.name], item.value];
						}
					}
					else {
						obj[item.name] = item.value;
					}
					return obj;
				}, {});

				data['action'] = newsletter_params.action;
				data['nonce'] = newsletter_params.nonce;

				$.ajax({
					url:      newsletter_params.admin_url,
					type:     'POST',
					dataType: 'json',
					data:     data,
					success:  function(response, status, jqXHR) {

						newsletter_button.attr('disabled', false);

						newsletter_button.addClass('loaded').on(transitionEndEvent, function() {
							var self = $(this);

							self.off(transitionEndEvent);
							self.removeClass('loading loaded');
						});

						if(response.success) {
							newsletter_form.empty();
							newsletter_form.append('<div class="success-message">' + response.data.message + '</div>')
						}
						else {
							newsletter_form.find('.invalid-tooltip').text(response.data.error);
							newsletter_form.find('input[type=email]').addClass('is-invalid');
						}
					}
				});

				return false;
			});
		}
	});

}(jQuery));

;(function($) {

	$(document).ready(function() {

		var transitionEndEvent = 'webkitTransitionEnd oTransitionEnd MSTransitionEnd transitionend';
		var lazyLoadRefreshEvent = 'lazyload.refresh';
		var body = $(document.body);

		// ---------- Global ----------

		body.on('click', '.recruteur-phone-toggle', function() {
			$(this).parent().toggleClass('active');
		});

		$('#recruteur-contact-modal, #recruteur-call-modal, #proposition-form-modal, #candidature-form-modal, #appointment-form-modal').on('show.bs.modal', function(e) {
			var self = $(this);
			var recruteur = $(e.relatedTarget).data('recruteur');
			var candidature = $(e.relatedTarget).data('candidature');
			var annonce = $(e.relatedTarget).data('annonce');
			var author = $(e.relatedTarget).data('author');

			self.find('input[name=recruteur]').val(recruteur);
			self.find('input[name=candidature]').val(candidature);
			self.find('input[name=annonce]').val(annonce);
			self.find('input[name=author]').val(author);
			self.find('.form-content').removeClass('d-none');
			self.find('.form-done, .form-done .success, .form-done .error').addClass('d-none');
		});

		$('#proposition-form-modal').on('show.bs.modal', function(e) {
			var self = $(this);
			var recruteur_phone = $(e.relatedTarget).data('phone');
			var recruteur_lastname = $(e.relatedTarget).data('lastname');
			var recruteur_firstname = $(e.relatedTarget).data('firstname');
			var recruteur_email = $(e.relatedTarget).data('email');

			self.find('input[name=phone]').val(recruteur_phone);
			self.find('input[name=lastname]').val(recruteur_lastname);
			self.find('input[name=firstname]').val(recruteur_firstname);
			self.find('input[name=email]').val(recruteur_email);
		});

		$('#candidature-form-modal').on('show.bs.modal', function(e) {
			var self = $(this);
			var annonce = $(e.relatedTarget).data('annonce');

			if(annonce) {
				self.find('.annonce').removeClass('d-none');
				self.find('.tele-cv').addClass('d-none');
			}
			else {
				self.find('.annonce').addClass('d-none');
				self.find('.tele-cv').removeClass('d-none');
			}
		});

		body.on('click', '.filters-toggle, .filters-btn', function() {

			if(window.innerWidth < 1024) {
				var filters = $('#annonces-filters');

				$('.filters-toggle').toggleClass('active');

				if(filters.hasClass('active')) {
					filters.removeClass('active');
					bodyScrollLock.enableBodyScroll(filters.find('.filters')[0]);
				}
				else {
					filters.addClass('active');
					bodyScrollLock.disableBodyScroll(filters.find('.filters')[0]);
				}
			}
		});

		// ---------- Handle Ajax Form ----------

		function handleAjaxForm(form, params) {

			var form_button = form.find('.submit');
			var form_content = form.find('.form-content');
			var form_done = form.find('.form-done');
			var form_success = form_done.find('.success');
			var form_error = form_done.find('.error');

			form.parsley().on('form:submit', function() {

				form_button.attr('disabled', true).addClass('loading');

				var form_data = new FormData(form[0]);
				form_data.append('action', params.action);
				form_data.append('nonce', params.nonce);

				$.ajax({
					url: params.admin_url,
					type: 'POST',
					dataType: 'json',
					data: form_data,
					processData: false,
					contentType: false,
					success: function(response, status, jqXHR) {

						form_button.attr('disabled', false).addClass('loaded');
						form_content.addClass('d-none');
						form_done.removeClass('d-none');

						if(response.success) {
							form_success.removeClass('d-none');

							if(response.data.cookie) {
								Cookies.set(response.data.cookie.name, response.data.cookie.value, {
									expires: 30,
									path: response.data.cookie.path,
									domain: response.data.cookie.domain
								});
							}
						}
						else {
							form_error.removeClass('d-none');
							console.error(response.data.error);
						}
					}
				});

				return false;
			});
		}

		$('form.recruteur-contact').each(function() {
			if(typeof recruteur_contact_params !== 'undefined') {
				handleAjaxForm($(this), recruteur_contact_params);
			}
		});

		$('form.recruteur-call').each(function() {
			if(typeof recruteur_call_params !== 'undefined') {
				handleAjaxForm($(this), recruteur_call_params);
			}
		});

		$('form.candidature-form').each(function() {
			if(typeof create_candidature_params !== 'undefined') {
				handleAjaxForm($(this), create_candidature_params);
			}
		});

		$('form.proposition-form').each(function() {
			if(typeof create_candidature_params !== 'undefined') {
				handleAjaxForm($(this), recruteur_proposition_params);
			}
		});

		$('form.appointment-form').each(function() {
			if(typeof create_appointment_params !== 'undefined') {
				handleAjaxForm($(this), create_appointment_params);
			}
		});

		$('form.pbi-contact').each(function() {
			if(typeof pbi_contact_params !== 'undefined') {
				handleAjaxForm($(this), pbi_contact_params);
			}
		});

		// ---------- Change Annonce Status ----------

		body.on('click', '[data-change-annonce-status]', function(e) {

			if(typeof change_annonce_status_params !== 'undefined') {

				var self = $(this);
				var data = {
					action: change_annonce_status_params.action,
					nonce: change_annonce_status_params.nonce,
					status: self.data('new-status'),
					annonce: self.data('annonce')
				}

				var loading_bar = $('.loading-bar');
				loading_bar.addClass('loading');

				$.ajax({
					url: change_annonce_status_params.admin_url,
					type: 'POST',
					dataType: 'json',
					data: data,
					success: function(response, status, jqXHR) {

						loading_bar.addClass('loaded');

						setTimeout(function() {
							loading_bar.removeClass('loading loaded');
						}, 500);

						if(response.success) {
							self.closest('.annonce-column').addClass('disabled');
						}
						else {
							console.error(response.data.error);
						}
					}
				});
			}
		});

		// ---------- Save Candidature ----------

		body.on('click', '[data-save-candidature]', function(e) {

			if(typeof save_candidature_params !== 'undefined') {

				var self = $(this);
				var data = {
					action: save_candidature_params.action,
					nonce: save_candidature_params.nonce,
					candidature: self.data('candidature')
				}

				$.ajax({
					url: save_candidature_params.admin_url,
					type: 'POST',
					dataType: 'json',
					data: data,
					success: function(response, status, jqXHR) {

						if(response.success) {

							if(response.data.status) {
								self.find('i').removeClass().addClass('ion-ios-star');
							}
							else {
								self.find('i').removeClass().addClass('ion-ios-star-outline');
							}
						}
						else {
							console.error(response.data.error);
						}
					}
				});
			}
		});

		// ---------- Recruteur References ----------

		$('.recruteur-references .carousel').each(function() {

			var self = $(this);

			var settings = {
				arrows: true,
				dots: false,
				infinite: false,
				centerMode: false,
				slidesToShow: 4,
				slidesToScroll: 4,
				responsive: [
					{
						breakpoint: 1280,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3
						}
					},
					{
						breakpoint: 768,
						settings: {
							arrows: false,
							dots: true,
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 480,
						settings: {
							arrows: false,
							dots: true,
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			};

			self.on('init reInit beforeChange afterChange', function() {
				$(window).trigger(lazyLoadRefreshEvent);
			});

			if(!self.hasClass('slick-initialized')) {
				self.slick(settings);
			}
		});

		// ---------- Recruteur References ----------

		$('.recruteur-testimonials .carousel').each(function() {

			var self = $(this);

			var settings = {
				arrows: true,
				dots: false,
				infinite: false,
				fade: true,
				singleMode: true,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							arrows: false,
							dots: true,
						}
					}
				]
			};

			self.on('init reInit beforeChange afterChange', function() {
				$(window).trigger(lazyLoadRefreshEvent);
			});

			if(!self.hasClass('slick-initialized')) {
				self.slick(settings);
			}
		});

	});

}(jQuery));

;(function($) {

	var animationEndEvent = 'webkitAnimationEnd oAnimationEnd MSAnimationEnd animationend';
	var transitionEndEvent = 'webkitTransitionEnd oTransitionEnd MSTransitionEnd transitionend';
	var lazyLoadRefreshEvent = 'lazyload.refresh';

	$(document).ready(function() {

		//======================================
		//	!---------- SECTIONS ----------
		//======================================

		// ---------- Activatable Text ----------

		$('.text-columns-section .carousel').each(function() {

			var self = $(this);

			var settings = {
				arrows:         false,
				infinite:       false,
				centerMode:     false,
				slidesToShow:   3,
				slidesToScroll: 3,
				responsive:     [
					{
						breakpoint: 1024,
						settings:   {
							slidesToShow:   2,
							slidesToScroll: 2,
							dots:           true,
						}
					},
					{
						breakpoint: 600,
						settings:   {
							slidesToShow:   1,
							slidesToScroll: 1,
							dots:           true,
						}
					}
				]
			};

			self.on('init reInit beforeChange afterChange', function() {
				$(window).trigger(lazyLoadRefreshEvent);
			});

			if(!self.hasClass('slick-initialized')) {
				self.slick(settings);
			}

			self.on('click', '.slick-slide', function() {
				var active = $(this);
				self.slick('slickGoTo', active.index());
			});
		});

		// ---------- Captions ----------

		$('.captions-carousel-section .captions').each(function() {

			var self = $(this);

			var settings = {
				asNavFor: (self.parent().hasClass('captions-carousel-section')) ? self.parent().find('.carousel') : null,
				arrows:   true,
				infinite: true,
				fade:     true,
			};

			self.on('init reInit beforeChange afterChange', function() {
				$(window).trigger(lazyLoadRefreshEvent);
			});

			if(!self.hasClass('slick-initialized')) {
				self.slick(settings);
			}
		});

		// ---------- Quotes ----------

		$('.quotes-section .carousel').each(function() {

			var self = $(this);

			var settings = {
				asNavFor:       (self.parent().hasClass('captions-carousel-section') && self.parent().find('.captions').length) ? self.parent().find('.captions') : null,
				arrows:         false,
				infinite:       true,
				fade:           true,
				adaptiveHeight: true,
			};

			self.on('init reInit beforeChange afterChange', function() {
				$(window).trigger(lazyLoadRefreshEvent);
			});

			if(!self.hasClass('slick-initialized')) {
				self.slick(settings);
			}
		});

		// ---------- References ----------

		$('.references-section .carousel').each(function() {

			var self = $(this);

			var settings = {
				arrows:         false,
				draggable:      false,
				infinite:       true,
				autoplay:       true,
				autoplaySpeed:  0,
				speed:          4000,
				cssEase:        'linear',
				pauseOnFocus:   true,
				pauseOnHover:   true,
				slidesToShow:   5,
				slidesToScroll: 1,
				responsive:     [
					{
						breakpoint: 1280,
						settings:   {
							slidesToShow: 4
						}
					},
					{
						breakpoint: 1024,
						settings:   {
							slidesToShow: 3
						}
					},
					{
						breakpoint: 768,
						settings:   {
							slidesToShow: 2
						}
					},
					{
						breakpoint: 480,
						settings:   {
							slidesToShow: 1
						}
					}
				]
			};

			self.on('init reInit beforeChange afterChange', function() {
				$(window).trigger(lazyLoadRefreshEvent);
			});

			if(!self.hasClass('slick-initialized')) {
				self.slick(settings);
			}
		});

	});

}(jQuery));

;(function($) {

	"use strict";

	var animationEndEvent = 'webkitAnimationEnd oAnimationEnd MSAnimationEnd animationend';
	var transitionEndEvent = 'webkitTransitionEnd oTransitionEnd MSTransitionEnd transitionend';
	var lazyLoadRefreshEvent = 'lazyload.refresh';

	window.lazySizesConfig.throttleDelay = 66;
	window.lazySizesConfig.preloadAfterLoad = true;

	$(document).ready(function() {

		//==================================
		//	!---------- GLOBAL ----------
		//==================================

		var body = $(document.body);

		// ---------- Bootstrap Fixes ----------

		$('.custom-file input').change(function(e) {
			var files = [];
			for(var i = 0; i < $(this)[0].files.length; i++) {
				files.push($(this)[0].files[i].name);
			}
			$(this).next('.custom-file-label').html(files.join(', '));
		});

		$('.datetimepicker-input').each(function() {
			$(this).datetimepicker({
				inline:     true,
				sideBySide: true,
				icons:      {
					up:       'ion-ios-arrow-up',
					down:     'ion-ios-arrow-down',
					previous: 'ion-android-arrow-back',
					next:     'ion-android-arrow-forward',
				}
			});
		});

		$.fn.selectpicker.Constructor.BootstrapVersion = '4';
		$('.selectpicker').selectpicker({
			style:      'btn-outline-primary',
			dropupAuto: false
		});

		// ---------- Replace hover rules by active on touchscreens ----------

		if(!!('ontouchstart' in window) || !!('msmaxtouchpoints' in window.navigator)) {
			for(var sheetI = document.styleSheets.length - 1; sheetI >= 0; sheetI--) {
				var sheet = document.styleSheets[sheetI];
				if(sheet.cssRules) {
					for(var ruleI = sheet.cssRules.length - 1; ruleI >= 0; ruleI--) {
						var rule = sheet.cssRules[ruleI];

						if(rule.selectorText) {
							rule.selectorText = rule.selectorText.replace(':hover', ':active');
						}
					}
				}
			}
		}

		// ---------- Menu ----------

		$('.mobile-menu-toggle').click(function(e) {
			e.preventDefault();

			var menu = document.getElementById("mobile-menu-wrapper");

			if(body.hasClass('mobile-menu-open')) {
				body.removeClass('mobile-menu-open');
				bodyScrollLock.enableBodyScroll(menu);
			}
			else {
				body.addClass('mobile-menu-open');
				bodyScrollLock.disableBodyScroll(menu);
			}
		});

		$('.mobile-menu-overlay').click(function(e) {
			e.preventDefault();

			$('.mobile-menu-toggle').trigger('click');
		});

		$('.navbar-menu a, .mobile-menu-wrapper a').click(function(e) {
			e.preventDefault();
			e.stopImmediatePropagation();
			e.stopPropagation();

			var self = $(this);
			var link_target = self.attr('href');
			var section_target = link_target.substring(link_target.lastIndexOf('#'), link_target.length);

			if(body.hasClass('home') && section_target.substring(0, 1) === '#') {

				body.removeClass('mobile-menu-open');

				$('html, body').stop().animate({
					scrollTop: $(section_target).offset().top - 70
				}, 1000);
			}
			else {
				window.location.href = link_target;
			}
		});

		$(window).on('load', function() {

			var link_target = window.location.href;
			var section_target = link_target.substring(link_target.lastIndexOf('#'), link_target.length);

			if(section_target.substring(0, 1) === '#' && $(section_target).length) {
				window.scrollTo(0, $(section_target).offset().top - 130);
			}

		});

		// ---------- Links ----------

		$('a[href="#"], .void-link').click(function(e) {
			e.preventDefault();
		});

		body.on('click', '[data-scroll-to]', function(e) {
			e.preventDefault();

			var self = $(this);
			var target = (self.is('a')) ? $(self.attr('href')) : $(self.data('scroll-to'));

			if(target.length) {
				var offset = (target.offset().top > 320) ? target.offset().top : 0;
				$('html, body').stop().animate({
					scrollTop: offset
				}, 1000);
			}
		});

		// ---------- Animsition ----------

		// $('.main-wrapper').animsition({
		// 	inClass:     'fade-in',
		// 	outClass:    'fade-out',
		// 	inDuration:  600,
		// 	outDuration: 400,
		// 	linkElement: 'a:not([target="_blank"]):not([href^="#"]):not([href^="mailto:"]):not([href^="tel:"]):not(.void-link):not([data-lity]):not([role=option])',
		// });

		// ---------- Modals ----------

		body.on('click', '.modal', function(e) {
			$(e.target).modal('hide');
		});

		// ---------- Carousels ----------

		$('.content-section .masonry').each(function() {
			var self = $(this);

			self.isotope({
				masonry:      {
					percentPosition: true
				},
				itemSelector: ".single-column"
			})
		});

		$('.home-references .carousel').each(function() {

			var self = $(this);

			var settings = {
				dots:           true,
				arrows:         false,
				infinite:       false,
				centerMode:     false,
				slidesToShow:   6,
				slidesToScroll: 6,
				responsive:     [
					{
						breakpoint: 1280,
						settings:   {
							slidesToShow:   5,
							slidesToScroll: 5
						}
					},
					{
						breakpoint: 1024,
						settings:   {
							slidesToShow:   4,
							slidesToScroll: 4
						}
					},
					{
						breakpoint: 768,
						settings:   {
							slidesToShow:   2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 480,
						settings:   {
							slidesToShow:   1,
							slidesToScroll: 1
						}
					}
				]
			};

			self.on('init reInit beforeChange afterChange', function() {
				$(window).trigger(lazyLoadRefreshEvent);
			});

			if(!self.hasClass('slick-initialized')) {
				self.slick(settings);
			}
		});

		$('.home-recruteurs .carousel').each(function() {

			var self = $(this);

			var settings = {
				dots:           true,
				arrows:         false,
				infinite:       false,
				centerMode:     false,
				slidesToShow:   4,
				slidesToScroll: 4,
				responsive:     [
					{
						breakpoint: 1280,
						settings:   {
							slidesToShow:   3,
							slidesToScroll: 3
						}
					},
					{
						breakpoint: 1024,
						settings:   {
							slidesToShow:   2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 480,
						settings:   {
							slidesToShow:   1,
							slidesToScroll: 1
						}
					}
				]
			};

			self.on('init reInit beforeChange afterChange', function() {
				$(window).trigger(lazyLoadRefreshEvent);
			});

			if(!self.hasClass('slick-initialized')) {
				self.slick(settings);
			}
		});

		$('.content-section .carousel').each(function() {

			var self = $(this);

			var settings = {
				arrows:         false,
				infinite:       false,
				centerMode:     false,
				slidesToShow:   4,
				slidesToScroll: 4,
				responsive:     [
					{
						breakpoint: 1024,
						settings:   {
							slidesToShow:   3,
							slidesToScroll: 3
						}
					},
					{
						breakpoint: 768,
						settings:   {
							slidesToShow:   2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 480,
						settings:   {
							slidesToShow:   1,
							slidesToScroll: 1
						}
					}
				]
			};

			self.on('init reInit beforeChange afterChange', function() {
				$(window).trigger(lazyLoadRefreshEvent);
			});

			if(!self.hasClass('slick-initialized')) {
				self.slick(settings);
			}
		});

		$('.content-section .slideshow').each(function() {

			var self = $(this);

			var settings = {
				infinite:      true,
				autoplaySpeed: 3000,
				arrows:        false,
				autoplay:      true,
				speed:         2000,
				fade:          true,
			};

			self.on('init reInit beforeChange afterChange', function() {
				$(window).trigger(lazyLoadRefreshEvent);
			});

			if(!self.hasClass('slick-initialized')) {
				self.slick(settings);
			}
		});

		$('.gallery').each(function() {
			var self = $(this);

			self.lightGallery({
				download: false,
			});
		});

		// ---------- LazyLoad ----------

		$(window).on(lazyLoadRefreshEvent, function() {

			window.lazySizes.loader.checkElems();
			window.lazySizes.autoSizer.checkElems();

		});

		$(window).trigger(lazyLoadRefreshEvent);

		// ---------- Vide ----------

		$(window).on('load', function() {

			$('.content-section .video-background').each(function() {
				var self = $(this);
				var video = self.data('video');
				var poster = self.data('poster');

				self.vide({
					mp4:    video,
					poster: poster
				}, {
					posterType: 'jpg'
				});

				setTimeout(function() {
					var video_object = self.data('vide').getVideoObject();
					video_object.play();
				});

			});
		});

		// ---------- Disclaimer ----------

		body.on('click', '.close-disclaimer', function(e) {
			e.preventDefault();

			Cookies.set('tc-disclaimer', true, {
				expires: 365,
				path:    '/',
			});

			$('#disclaimer').fadeOut(1000, function() {
				$(this).remove();
			});

			body.removeClass('disclaimer-open');
		});

		$('#cookies-law').on('closed.bs.alert', function() {

			Cookies.set('tc-cookies', true, {
				expires: 365,
				path:    '/',
			});

		});

		// ---------- ACF Forms ----------

		$('form.acf-form').each(function() {

			var form = $(this);
			var form_button = form.find('[type=submit]');

			form_button.on('click', function() {
				$(this).addClass('loading');
			});

			acf.addFilter('validation_complete', function(json, $form) {

				form_button.addClass('loaded');

				setTimeout(function() {
					form_button.removeClass('loading loaded');
				}, 500);

				return json;
			});
		});

	});

}(jQuery));
